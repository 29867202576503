@charset "UTF-8";
.data-table {
  text-transform: capitalize; }

.data-table .button {
  margin: 0 0.25em 0.25em 0; }

.data-table .email {
  text-transform: lowercase; }

.data-table .action-cell {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.data-table .action-button {
  margin: 0 0.5rem 0.5rem 0 !important;
  flex: 1 0 auto; }

.data-table .action-button.full-width {
  width: 50%; }

.data-table .action-components-cell {
  text-align: center;
  width: 100%; }

.data-table .action-components-cell.folded, .data-table .action-components-cell.unfolded {
  text-align: center; }

.data-table .action-components-cell.folded:hover, .data-table .action-components-cell.unfolded:hover {
  cursor: pointer; }

.data-table .action-components-cell .show-more {
  width: 100%; }

.data-table .action-components-cell.unfolded .show-more::after {
  content: '▲';
  width: 15px;
  height: 15px; }

.data-table .action-components-cell.folded {
  padding: 0; }

.data-table .action-components-cell.folded .show-more::before {
  content: '▼';
  width: 15px;
  height: 15px; }

.data-table .action-components-cell.folded .action-components-container {
  display: none; }

.data-table .action-components-container {
  display: flex;
  flex-wrap: wrap; }

.data-table .action-components-container .action-button {
  flex: 1 0 100%; }
