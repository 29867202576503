.sign-component {
  display: flex;
  flex-direction: column;
  align-items: center; }

.sign-component .pad {
  border: 1px solid grey; }

.sign-component .pad .canvas {
  width: 350px;
  height: 200px; }

.sign-component .buttons {
  padding: 0.5rem; }
