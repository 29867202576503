.uploader-component .file-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start; }

.uploader-component .file {
  position: relative;
  width: 80px;
  margin: 0.5rem;
  overflow: hidden;
  text-align: center;
  background: #c4deff;
  border-radius: 5px; }

.uploader-component .file .content {
  display: block;
  padding: 0.5rem;
  max-height: 80px;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden; }

.uploader-component .file .delete {
  display: block;
  color: white;
  background: #f31d1d; }

.uploader-component .file:hover {
  cursor: pointer; }
