@media (min-width: 768px) {
  .form-component {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: -1rem; } }

.form-component .form-field {
  margin: 1rem 1rem 0 0 !important; }

.form-component .form-button {
  margin: 1rem 1rem 0 0 !important; }
