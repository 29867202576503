.dashboard-page {
  display: flex;
  flex-direction: column;
  background: white;
  overflow: hidden;
  width: 100%;
  height: 100vh; }

.dashboard-page > .header {
  width: 100%;
  z-index: 1000; }

.dashboard-page > .dash-wrapper {
  width: 100%;
  height: 100%;
  min-height: 50vh; }

.dashboard-page > .dash-wrapper > .dash-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

.dashboard-page > .footer {
  width: 100%;
  z-index: 400;
  text-align: right;
  background: #1a2226;
  color: white; }

.dashboard-page > .loader {
  position: absolute; }
